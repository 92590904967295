<template>
  <div id="app">
    <div class="pseudo">
      <img src="./assets/jiveoffT.png" alt="jiveoff" />
      <h1>JiveOff</h1>
    </div>
    <div class="lang">
      <a
        href="#"
        v-for="lang in languages"
        :key="lang.flag"
        @click="changeLang(lang.code)"
      >
        <img :src="lang.flag" alt="flag" />
      </a>
    </div>
    <transition name="fade" mode="out-in">
      <div :key="selectedLang.code" class="readme" v-html="markdownHtml"></div>
    </transition>
    <div class="jiveoff">
      <img src="./assets/jiveoffT.png" alt="jiveoff" />
    </div>
  </div>
</template>

<style>
@import url("https://fonts.googleapis.com/css2?family=Red+Hat+Display:wght@400;500&display=swap");

body {
  background-image: url("./assets/bg.png");
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin: 0;
}

#app {
  font-family: "Red Hat Display", sans-serif;
  display: flex;
  width: 100%;
}

ul {
  list-style-type: none;
  margin: 10px 0;
}

blockquote ul:first-of-type {
  padding-left: 0;
}

li {
  margin-bottom: 10px;
}

a,
code {
  color: #fff;
  text-decoration: none;
  font-weight: bold;
  background: rgba(255, 255, 255, 0.1);
  padding: 0.1rem 0.5rem;
}

.lang {
  position: absolute;
  z-index: 5;
  display: block;
  right: 0.7rem;
  top: 0.7rem;
  width: 35px;
}

.lang a {
  background: none;
  margin: 0;
  padding: 0;
}

.lang a img {
  border-radius: 3px;
}

.pseudo {
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  color: #fff;
}

.pseudo img {
  width: 56px;
  height: 56px;
}

.jiveoff {
  width: 50vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  right: 0;
}

.readme {
  width: 50%;
  height: 100%;
  font-size: 1rem;
  color: #fff;

  padding: 1rem;
  padding-left: 2rem;
}

.readme img {
  margin-top: 0.5rem;
}

h2 {
  font-size: 54px;
  font-weight: 500;
  margin-top: 0;
  margin-bottom: 1rem;
}

h3 {
  display: initial;
}

blockquote {
  border-left: 5px solid #fff;
  padding: 0.1rem 1rem;
  margin-left: 0;
  margin-right: 0;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.1);
  width: 80%;
}

@media screen and (max-width: 768px) {
  body {
    margin: 1rem;
  }

  #app {
    flex-direction: column;
  }

  .jiveoff {
    width: 100vw;
    display: none;
  }

  .readme {
    width: 100%;
    padding: 0;
  }

  .readme::after {
    background: linear-gradient(to bottom, transparent 0%, black 100%);
  }

  blockquote {
    width: calc(100% - 2rem);
  }

  .pseudo {
    display: flex;
  }
}

p {
  overflow-wrap: anywhere;
}

.readme > p:first-of-type {
  display: none;
}

.readme img:first-of-type {
  display: none;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>

<script>
import { marked } from "marked";
import axios from "axios";

export default {
  name: "Home",
  data() {
    return {
      markdown: "",

      selectedLang: "",
      languages: {
        en: {
          code: "en",
          file: "https://raw.githubusercontent.com/JiveOff/JiveOff/main/README.md",
          content: "",
          flag: "https://raw.githubusercontent.com/hampusborgos/country-flags/main/svg/gb.svg",
        },
        fr: {
          code: "fr",
          file: "https://raw.githubusercontent.com/JiveOff/JiveOff/main/README-FR.md",
          content: "",
          flag: "https://raw.githubusercontent.com/hampusborgos/country-flags/main/svg/fr.svg",
        },
      },
    };
  },
  computed: {
    markdownHtml() {
      return marked.parse(this.markdown);
    },
  },
  mounted() {
    this.selectedLang =
      this.languages[
        navigator.language || navigator.userLanguage === "fr" ? "fr" : "en"
      ];
    this.getReadme();
  },
  methods: {
    async getReadme() {
      if (!this.selectedLang.content) {
        const { data } = await axios.get(this.selectedLang.file);
        this.selectedLang.content = data;
      }
      this.markdown = this.selectedLang.content;
    },
    changeLang(lang) {
      this.selectedLang = this.languages[lang];
      this.getReadme();
    },
  },
};
</script>
